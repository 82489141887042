import * as React from 'react';
import MediaQuery from 'react-responsive';
import {Segment} from 'semantic-ui-react';
import {TranslatorProvider} from 'react-translate';
import {Route, Router, Switch} from 'react-router-dom';
import {get} from 'lodash';

import history from "./history";
import Appbar from './components/appbar/Appbar';

// modules
import Homepage from './modules/Homepage';
import Settings from './modules/settings';
import Metadata from './modules/settings/Metadata';
import ThemeCreator from './modules/settings/ThemeCreator';
import Dictionary from "./modules/settings/Dictionary";
import Users from "./modules/settings/Users";
import ImportExport from "./modules/settings/ImportExport";
import Notifications from './modules/notifications/Notifications';
import SearchResult from "./modules/searchResults/SearchResults";
import SearchResultsPrimitive from "./modules/searchResults/SearchResultsPrimitive";
import SearchResultsAlphabet from "./modules/searchResults/SearchResultsAlphabet";
import AddTerm from "./modules/TermAdd";
import Term from "./modules/Term";
import TermSidebar from "./modules/TermSidebar";
import TermEdit from "./modules/TermEdit";
import TermEdited from "./modules/TermEdited";
import StaticInfo from "./modules/StaticInfo";
import StaticContact from "./modules/StaticContact";
import StaticHelp from "./modules/StaticHelp";
import Remark from "./modules/Remark";
import Statistics from "./modules/Statistics";
import Visualization from "./modules/Visualization";

// central modules
import HomepageC from './modulesCentral/Homepage';
import AddNewC from './modulesCentral/AddNew';
import SettingsC from './modulesCentral/settings';
import UsersC from "./modulesCentral/settings/Users";

// universal modules
import Edit from './modulesUniversal/UserEdit';
import Tech from "./modulesUniversal/settings/Tech";

//import {} from './utils/utils';
import * as c from './constants';

import translations from './constants/translations';
import EnhancedKeyboard from "./components/enhanced/EnhancedKeyboard";

import Context from "./Context";
import ContextUser from "./ContextUser";

const View = () => {
    const {settings} = React.useContext(Context);
    const {loggedUser} = React.useContext(ContextUser);

    const [visible, setVisible] = React.useState(window.innerWidth > 300 && settings.tech && settings.tech.show_tree === "true");
    const [width, setWidth] = React.useState(350);
    const [scale, setScale] = React.useState(false);
    const [currentLang, setCurrentLang] = React.useState(loggedUser.lang ? loggedUser.lang : 'en'); //temporary solution - waiting for API

    React.useEffect(() => {
        setVisible(window.innerWidth > 300 && settings.tech && settings.tech.show_tree === "true");
    }, [settings]);

    React.useEffect(() => {
        if (loggedUser && loggedUser.lang) setCurrentLang(loggedUser.lang);
    }, [loggedUser]);

    const sideScale = () => {
        setScale(true);
    };

    const sideScaleMove = (e) => {
        if (scale) {
            if (e.clientX < (window.innerWidth - 250)) {
                if (e.clientX > 200) setWidth(e.clientX + 5);
                else setVisible(false);
            } else {
                setWidth(window.innerWidth - 250);
            }
        }
    };

    const sideScaleStop = () => {
        setScale(false);
    };

    return (
        <React.StrictMode>
            <TranslatorProvider translations={get(translations, currentLang)}>
                <Router history={history}>
                    <div onMouseMove={(e) => sideScaleMove(e)} onMouseUp={sideScaleStop}>
                        <EnhancedKeyboard/>
                        <Appbar currentLang={currentLang} setCurrentLang={setCurrentLang}/>
                        <div id='mainBody'>
                            <div className="main">
                                <Switch>
                                    {c.CENTRAL ? (
                                        <>
                                            <Route exact path="/public/central" component={HomepageC}/>
                                            <Route exact path="/public/central/addNew" component={AddNewC}/>
                                            <Route exact path="/public/central/settings" component={SettingsC}/>
                                            <Route exact path="/public/central/settings/tech" component={Tech}/>
                                            <Route exact path="/public/central/settings/users" component={UsersC}/>
                                            <Route exact path="/public/central/edit" component={Edit}/>
                                        </>
                                    ) : <>
                                        <Route
                                            exact path={["/tezaurus", "/tezaurus/term/:id/:timestamp?/:date?"]}
                                            render={(props) => (
                                                <div>
                                                    <TermSidebar sideScale={sideScale} setVisible={setVisible}
                                                                 visible={visible} width={width}
                                                                 id_prop={props.match.params.id}
                                                    />
                                                    <MediaQuery minWidth={c.BREAKPOINTS.largeMin}>
                                                        {matches => (
                                                          <div style={matches ? {} : visible ? {
                                                              width: 'calc(100% - ' + width + 'px)',
                                                              marginLeft: width + 'px',
                                                              transition: 'all .2s ease'
                                                          } : {
                                                              width: 'calc(100% - 2em)',
                                                              marginLeft: '2em',
                                                              transition: 'all .4s ease'
                                                          }}>
                                                              <Segment basic className='main-segment main-segment--padded'>
                                                                  <Route exact path="/tezaurus" render={() => <Homepage lang={currentLang}/>}/>
                                                                  <Route exact path="/tezaurus/term/:id/:timestamp?/:date?" component={Term}/>
                                                              </Segment>
                                                          </div>
                                                        )}
                                                    </MediaQuery>
                                                </div>
                                            )}
                                        />
                                        <Route exact path="/tezaurus/stats" component={Statistics}/>
                                        <Segment basic className='main-segment main-segment--padded'>
                                            <Route exact path="/tezaurus/notifications" component={Notifications}/>
                                            <Route exact path="/tezaurus/edit" component={Edit}/>
                                            <Route exact path="/tezaurus/settings" component={Settings}/>
                                            <Route exact path="/tezaurus/settings/metadata" component={Metadata}/>
                                            <Route exact path="/tezaurus/settings/theme" component={ThemeCreator}/>
                                            <Route exact path="/tezaurus/settings/tech" component={Tech}/>
                                            <Route exact path="/tezaurus/settings/dictionary" component={Dictionary}/>
                                            <Route exact path="/tezaurus/settings/users" component={Users}/>
                                            <Route exact path="/tezaurus/settings/import-export" component={ImportExport}/>
                                            <Route exact path="/tezaurus/filter" component={SearchResult}/>
                                            <Route exact path="/tezaurus/search/:term?" component={SearchResultsPrimitive}/>
                                            <Route exact path="/tezaurus/alphabet/:code?" component={SearchResultsAlphabet}/>
                                            <Route exact path="/tezaurus/add" component={AddTerm}/>
                                            <Route exact path="/tezaurus/termEdit" component={TermEdit}/>
                                            <Route exact path="/tezaurus/info" render={() => <StaticInfo lang={currentLang}/>}/>
                                            <Route exact path="/tezaurus/editedTerms" component={TermEdited}/>
                                            <Route exact path="/tezaurus/contact" component={StaticContact}/>
                                            <Route exact path="/tezaurus/remark/:template?" component={Remark}/>
                                            <Route exact path="/tezaurus/help" component={StaticHelp}/>
                                            <Route exact path="/tezaurus/visualization/:id" render={({match}) => <Visualization id={match.params.id}/>}/>
                                        </Segment>
                                    </>}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </Router>
            </TranslatorProvider>
        </React.StrictMode>
    );
};

export default View;
