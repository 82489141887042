import * as React from 'react'
import {Link} from 'react-router-dom'
import {Button, List, Modal, Header, Icon} from 'semantic-ui-react'

import {timeFormat, dateFormat} from '../../utilsNew/date'
import {notificationAction} from '../_actions'
import {translate} from 'react-translate'

const notificationActionHandler = (action, term_id, comment, getListNotifications) => notificationAction(action, term_id, comment).then(getListNotifications)

const ModalContent = ({positive, term_id, getListNotifications, setModalOpen, t}) => {
  const [comment, setComment] = React.useState('')

  return <>
    <Header icon='archive' content={positive ? t('SURE_APPROVE') : t('SURE_DENY')}/>
    <Modal.Content>
      <label htmlFor='notifComment'>{t('COMMENT')} {!positive && <b>{t('REQUIRED')}</b>}</label>
      <textarea id='notifComment' value={comment} onChange={e => setComment(e.target.value)}/>
    </Modal.Content>
    <Modal.Actions>
      <Button basic color='red' inverted onClick={() => setModalOpen(false)}>
        <Icon name='remove'/> {t('CANCEL')}
      </Button>
      <Button color='green' disabled={!(positive || comment)} inverted onClick={() => {
        notificationActionHandler(positive ? 'schvaleno' : 'zamitnuto', term_id, comment, getListNotifications)
          .then(() => setModalOpen(false))
      }}>
        <Icon name='checkmark'/> {t('YES')}
      </Button>
    </Modal.Actions>
  </>
}

const NotificationItem = ({data, getListNotifications, t}) => {
  const [modalOpen1, setModalOpen1] = React.useState(false)
  const [modalOpen2, setModalOpen2] = React.useState(false)

  return (
    <List.Item style={{opacity: data.read ? 1 : 0.5}} className='notificationItem'>
      {data.from === 'registr' ? (
        <List.Icon name='location arrow' rotated='counterclockwise' verticalAlign='middle'/>
      ) : (
        <List.Icon name='long arrow alternate down' size='large' verticalAlign='middle'
                   style={{paddingLeft: '3px'}}/>
      )}
      <List.Content>
        <List.Content floated='left' style={{margin: 0, maxWidth: '31em'}}>
          <List.Header>{data.from}</List.Header>
        </List.Content>
        <List.Content floated='right' style={{textAlign: 'right', marginLeft: 0}}>
          <span>{timeFormat(data.time_received)} - {dateFormat(data.time_received)}</span><br/>
        </List.Content>
        <br/>
        <List.Content>
          <List.Description>
            {data.message}: <Link to={'/tezaurus/term/' + data.term_id}>{data.term}</Link>
          </List.Description>
        </List.Content>
      </List.Content>
      <div className='notificationItem-buttons'>
        <Button.Group vertical labeled icon size='mini'>
          <Modal trigger={<Button onClick={() => setModalOpen1(true)} positive content={t('APPROVE')} icon='checkmark'/>} open={modalOpen1} basic size='small'>
            <ModalContent positive term_id={data.term_id} getListNotifications={getListNotifications} setModalOpen={setModalOpen1} t={t}/>
          </Modal>

          <Modal trigger={<Button onClick={() => setModalOpen2(true)} negative content={t('DENY')} icon='close'/>} open={modalOpen2} basic size='small'>
            <ModalContent term_id={data.term_id} getListNotifications={getListNotifications} setModalOpen={setModalOpen2} t={t}/>
          </Modal>
        </Button.Group>
      </div>
    </List.Item>
  )
}

export default translate('Term')(NotificationItem)
