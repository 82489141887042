import React from 'react';

const magicConstant = 72;

const EnhancedImg = ({src, alt}) => {
    const [height, setHeight] = React.useState(0);

    React.useEffect(() => {
        let img = document.createElement('img');
        img.setAttribute('src', src);
        setTimeout(() => {
            setHeight(img.height / magicConstant);
        }, 0);
    }, []);

    return (
        <img style={{height: height + 'em', verticalAlign: 'middle'}}
             src={src}
             alt={alt}
        />
    );
};

export default EnhancedImg;
