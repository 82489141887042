import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, Button} from 'semantic-ui-react';
import {authorize} from "../../utilsNew";
import ContextUser from "../../ContextUser";

const BasicSettingsPage = ({history, current, children, saved, t}) => {
  const {loggedUser} = React.useContext(ContextUser);

  return (
    <Segment
      className='settings-segment'
      textAlign="center"
      vertical={true}
    >
      <Container text={true}>
        {saved ? (
          <Header as="h2" icon={true} onClick={() => history.push('/public/central/settings')} style={{color: 'green'}}>
            <Icon name="save outline"/>
            {t('SAVED')}
          </Header>
        ) : (
          <Header as="h2" icon={true} onClick={() => history.push('/public/central/settings')}>
            {saved === null ? <Icon name="spinner loading icon" style={{opacity: 0.4}}/> : <Icon name="settings"/>}
            {t('SETTINGS')}
          </Header>
        )}
        <br/>
        {authorize(['it_admin', 'operator'], loggedUser.roles) && (
          <Button positive={current === 1} active={current === 1} icon='setting' content={t('TECH')}
                  labelPosition='left'
                  onClick={() => history.push('/public/central/settings/tech')}
          />
        )}
        {authorize(['referent'], loggedUser.roles) && (
          <Button positive={current === 2} active={current === 2} icon='users' content={t('USERS')} labelPosition='left'
                  onClick={() => history.push('/public/central/settings/users')}
          />
        )}
        <br/><br/>
        {children}
      </Container>
    </Segment>
  );
};

export default translate('Settings')(BasicSettingsPage);
