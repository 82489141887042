import * as React from 'react';
import {translate} from "react-translate";
import {Link} from 'react-router-dom';
import {Container, Segment, List, Button} from 'semantic-ui-react';

import {searchWithFilter} from "./_actionsSearch";
import History from "../../history";
import ContextUser from "../../ContextUser";

const SearchResultsPrimitive = ({match, t}) => {
  const [data, setData] = React.useState(0);
  const {loggedUser} = React.useContext(ContextUser);

  React.useEffect(() => {
    getSearch({search: match.params.term})
  }, [match]);

  const getSearch = async (search) => {
    setData(undefined);
    setData(await searchWithFilter(search));
  };

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        {data && data.terminologicky && data.terminologicky.results.length ? (
          <List link className='searchResults'>
            {data.terminologicky.results.map((e) => (
              <List.Item key={e.id}>
                <Link to={'/tezaurus/term/' + e.id} style={{marginLeft: '.5em'}}>
                  {e.title}
                </Link>
              </List.Item>
            ))}
          </List>
        ) : data && (data.length === 0 || (data.terminologicky && data.terminologicky.length === 0) || (data.terminologicky.results && data.terminologicky.results.length === 0)) ? (
          <>
            <i style={{opacity: 0.4}}>{t('NO_RESULTS')}</i>
            <br/><br/>
            {(loggedUser && loggedUser.roles && loggedUser.roles.length === 1 && loggedUser.roles[0] === "host") && (
              <Button onClick={() => {
                History.push('/tezaurus/remark/' + t('NEW') + ' – ' + (match.params.term))
              }} className='term-editBttn'>{t('NEW_TERM_SUGGEST')}</Button>
            )}
          </>
        ) : data === undefined ? (
          <>
            <i style={{opacity: 0.4}}>{t('LOADING')}</i>
            <br/><br/>
            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
          </>
        ) : data === 0 ? (
          <></>
        ) : (
          <i style={{opacity: 0.4, color: 'red'}}>{t('ERROR')}</i>
        )}
      </Container>
    </Segment>
  );
};

export default translate('SearchResults')(SearchResultsPrimitive);
