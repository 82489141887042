import * as React from 'react';
import {translate} from 'react-translate';
import {Container, Header, Icon, Segment, Input, Button} from 'semantic-ui-react';
import {Form, TextArea, Checkbox} from "informed";
import queryString from 'query-string'

import Context from "../Context";
import ContextUser from "../ContextUser";
import ContextInitialValues, {ProviderInitialValues} from "../ContextInitialValues";
import History from "../history";
import {gettrans, action_delete, action_return_to_rework} from './_actions';
import TermLinks from "./TermEdit/TermLinks";
import MainHeader from "./TermEdit/MainHeader";
import Translate from "./TermEdit/Translate";
import Definitions from "./TermEdit/Definitions";
import Others from "./TermEdit/Others";
import Pictures from "./TermEdit/Pictures";
import Videos from "./TermEdit/Videos";
import {authorize, removeFromArr} from "../utilsNew";
import handleSubmit from "./termEdit_handleSubmit";

let req_trans = false;

const TermEdit = ({t}) => {
    const {settings, loading} = React.useContext(Context);
    const {loggedUser} = React.useContext(ContextUser);
    const {id, defaultLang, entry, title, initialValues, langsDef, changed} = React.useContext(ContextInitialValues);

    const [optionsLang, setOptiuonsLang] = React.useState([]);
    const [newLangs, setNewLangs] = React.useState({});
    const [deleted, setDeleted] = React.useState({hyper: [], synonyms: {}, antonyms: {}, homonyms: {}});
    const [newSees, setNewSees] = React.useState({path: [], synonym: [], antonym: [], homonym: []});
    const [errorsState, setErrorsState] = React.useState(0);
    const [errorsStringsState, setErrorsStringsState] = React.useState([]);
    const [loadingSubmit, setLoadingSubmit] = React.useState(false);

    const newTerm = queryString.parse(History.location.search).new;

    React.useEffect(() => {
        if (settings && settings.dict) {
            req_trans = ((settings.dict.req_trans) ? settings.dict.req_trans : false);
            setOptiuonsLang(settings.dict.langs.map(e => ({key: e, value: e, flag: e, text: e.toUpperCase()})));
        }
    }, [settings]);

    const handleSelect = (option, i) => {
        const tmp = JSON.parse(JSON.stringify(newLangs));
        tmp[i] = option.value;
        setNewLangs(tmp);
    };

    const handleReturnToRework = async event => {
        event.preventDefault();
        if (window.confirm(t('RETURN_SURE'))) {
            await action_return_to_rework(entry['@id']);
            History.push('/tezaurus');
        }
    };

    const handleDelete = async event => {
        event.preventDefault();
        if (window.confirm(t('DELETE_SURE'))) {
            await action_delete(entry['@id']);
            History.push('/tezaurus');
        }
    };

    const hintTransNew = i => hintTrans(newLangs[i] ? newLangs[i] : defaultLang);
    const hintTrans = async lang => (await gettrans(lang, newTerm ? newTerm : entry.terms.term[0].$))['trans_cands'];

    return (
        <Segment
            textAlign="center"
            style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
            vertical={true}
        >
            <Container text={true}>
                {!loading && !loadingSubmit && initialValues ? (
                    <Form className='termForm' initialValues={initialValues}
                          onSubmit={data => handleSubmit(data, langsDef, setErrorsState, setErrorsStringsState, setLoadingSubmit, entry, req_trans, defaultLang, newLangs, newSees, deleted, id, t)}
                    >{({formApi}) => (<>
                        <MainHeader edit title={title} id={entry ? entry['@id'] : false}/>
                        {!!errorsState && (
                            <div className='errors'>
                                <p><b>{t('ERRORS_FOUND')}: {errorsState}</b></p>
                                {errorsStringsState.map(e => <p key={e}>{e}</p>)}
                            </div>
                        )}
                        <br/>
                        <div>
                            {t('TRANS_SHORT')}
                            <div style={{display: 'inline-block', verticalAlign: 'sub', padding: '1em'}}>
                                <div className='ui checked fitted checkbox'>
                                    <Checkbox field={'abbrev'} slider/> {/* TODO: highlight */ }
                                    <label/>
                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className={formApi.getValue('abbrev') ? 'abbrev' : 'notAbbrev'}>
                            <Translate edit
                                       entry={entry}
                                       newTerm={newTerm}
                                       selectOptions={optionsLang}
                                       handleSelect={handleSelect}
                                       defaultLang={defaultLang}
                                       hintTrans={hintTrans}
                                       hintTransNew={hintTransNew}
                                       initialValues={initialValues}
                                       changed={changed && changed.terms && changed.terms.term}
                            />
                            {loggedUser && loggedUser.roles && authorize(settings.roles && removeFromArr(removeFromArr(settings.roles.map(e => e.role), 'prekladatel'), 'recenzent_preklad'), loggedUser.roles) && (
                                <>
                                    <Definitions edit
                                                 settings={settings}
                                                 entry={entry}
                                                 newTerm={newTerm}
                                                 formApi={formApi}
                                                 optionsLang={optionsLang}
                                                 changed={changed && changed.defs && changed.defs.def}
                                    />
                                    <TermLinks entry={entry} edit deleted={deleted} setDeleted={setDeleted}
                                               newSees={newSees} setNewSees={setNewSees} newTerm={newTerm}
                                               title={title}
                                    />
                                    <Pictures edit
                                              entry={entry}
                                              newTerm={newTerm}
                                              changed={changed && changed.imgs && changed.imgs.img}
                                    />
                                    <Videos edit
                                            entry={entry}
                                            newTerm={newTerm}
                                            changed={changed && changed.videos && changed.videos.video}
                                    />
                                    <Others edit
                                            entry={entry}
                                            newTerm={newTerm}
                                            changed={changed && changed.exlinks && changed.exlinks.exlink}
                                    />
                                </>
                            )}
                            <Segment>
                                <h3>{t('COMMENT')}</h3>
                                <Input>
                                    <div className="ui form"><TextArea field={'comment'}/></div>
                                </Input>
                            </Segment>
                        </div>
                        <br/>
                        {settings.dict.simpleprocess !== "true" && loggedUser && loggedUser.roles && authorize(['hl_redaktor', 'asist_hl', 'obor_revizor', 'recenzent_preklad', 'jazyk_specialista', 'jazyk_korektor', 'tajemnik', 'obor_redaktor'], loggedUser.roles) && (
                          <>
                            <div style={{lineHeight: '2em'}}>
                                {t('DENY')}
                                <div style={{display: 'inline-block', verticalAlign: 'sub', padding: '1em'}}>
                                    <div className='ui checked fitted slider checkbox'>
                                        <Checkbox field={'approval'} slider/>
                                        <label/>
                                    </div>
                                </div>
                                {t('APPROVE')}
                            </div>
                            {loggedUser && loggedUser.roles && authorize(['asist_hl', 'revizor', 'obor_redaktor', 'obor_revizor', 'tajemnik'], loggedUser.roles) && (
                              <Button onClick={handleReturnToRework}>{t('RETURN_TO_REWORK')}</Button>
                            )}
                            <br/><br/>
                          </>
                        )}
                        {loggedUser && loggedUser.roles && authorize(['asist_hl', 'revizor', 'obor_redaktor', 'obor_revizor', 'tajemnik'], loggedUser.roles) && (
                            <Button onClick={handleDelete}>{t('DELETE')}</Button>
                        )}
                        <Button positive type="submit">{t('SAVE')}</Button>
                    </>)}</Form>
                ) : (
                    <>
                        <Header as="h2" icon={true} style={{marginBottom: 0}}>
                            <Icon name="sitemap"/>
                            {(!loading && (initialValues === undefined)) ? (
                                t('404')
                            ) : (
                                t('LOADING')
                            )}
                        </Header>
                        <Header as="h6" style={{marginTop: 0}}>
                            ID: {id}
                        </Header>
                        {(loadingSubmit || (loading && initialValues !== undefined)) && (
                            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
                        )}
                    </>
                )}
            </Container>
        </Segment>
    );
};

const TermEditWithContext = props => <ProviderInitialValues><TermEdit {...props}/></ProviderInitialValues>;

export default translate('Term')(TermEditWithContext);
