import urlRegex from "url-regex";

import {update} from "./_actions";
import History from "../history";
import getNameFromCode from "../constants/languages";
import {numberOfFields as def_numberOfFields} from "./TermEdit/Definitions";

let errors = 0;
let errorsStrings = [];

const handleSubmit = async (data, langsDef, setErrorsState, setErrorsStringsState, setLoadingSubmit, entry, req_trans, defaultLang, newLangs, newSees, deleted, id, t) => {
  const buildOutputValuesFromForm = (e) => {
    const output = entry ? JSON.parse(JSON.stringify(entry)) : {};
    let i, j, json;
    errors = 0;
    errorsStrings = [];

    output['@abbrev'] = e.abbrev.toString();

    if (!id || entry.defs || e.defNew) {
      output.defs = {};
      output.defs.def = [];
      let firstAndOnlyValidDomain = undefined;
      for (i = 0; i < (entry ? (entry.defs && entry.defs.def.length) : 1); i++) {
        if (e['def$' + i]) {
          let definition = e['def$' + i];
          let domain = e['defObor' + i];
          let source = e['defSource' + i];
          if (!(!definition || definition === '') && (!domain || domain === '')) {
            errorsStrings.push(t('ERROR_SELECT_DOMAIN_FOR_DEF') + definition);
            errors++;
          } else if (!id) {
            if (firstAndOnlyValidDomain === undefined) {
              firstAndOnlyValidDomain = domain;
            } else if (firstAndOnlyValidDomain !== domain) {
              errorsStrings.push(t('ERROR_MULTIPLE_DIFFERENT_DOMAINS') + definition + ' – ' + firstAndOnlyValidDomain + ' & ' + domain);
              errors++;
            }
          }
          if (!(!definition || definition === '') && (!source || source === '')) {
            errorsStrings.push(t('ERROR_SOURCE_FOR_DEF') + definition);
            errors++;
          }
          output.defs.def.push({
            'orderNumber': e['orderNumber' + i],
            'text': {'$': definition},
            'html': {'$': definition},
            '@lang': langsDef.oldTerms[i] ? langsDef.oldTerms[i] : defaultLang,
            'zdroj': {'$': source},
            'law': {'$': e['defLaw' + i]},
            'math': {'$': e['defMath' + i]},
            'table': {'$': e['defTable' + i]},
            '@obor': domain,
            'druh': {'$': e['defDruh' + i]},
            'type': {'$': e['defType' + i]},
            'moreinfo': {'$': e['defMoreinfo' + i]},
            'private': {'$': e['defPrivate' + i]},
          });
        }
      }
      if (e.defNew) {
        for (i = 0; i < e.defNew.length; i += def_numberOfFields) {
          if (e.defNew[i]) {
            let domain = e.defNew[i + 5];
            let source = e.defNew[i + 1];
            if (!domain || domain === '') {
              errorsStrings.push(t('ERROR_SELECT_DOMAIN_FOR_DEF') + e.defNew[i]);
              errors++;
            } else if (!id) {
              if (firstAndOnlyValidDomain === undefined) {
                firstAndOnlyValidDomain = domain;
              } else if (firstAndOnlyValidDomain !== domain) {
                errorsStrings.push(t('ERROR_MULTIPLE_DIFFERENT_DOMAINS') + e.defNew[i] + ' – ' + firstAndOnlyValidDomain + ' & ' + domain);
                errors++;
              }
            }
            if (!source || source === '') {
              errorsStrings.push(t('ERROR_SOURCE_FOR_DEF') + e.defNew[i]);
              errors++;
            }
            output.defs.def.push({
              'text': {'$': e.defNew[i]},
              '@lang': langsDef.newTerms[i / def_numberOfFields] ? langsDef.newTerms[i / def_numberOfFields] : defaultLang,
              'zdroj': {'$': source},
              'law': {'$': e.defNew[i + 2]},
              'math': {'$': e.defNew[i + 3]},
              'table': {'$': e.defNew[i + 4]},
              '@obor': domain,
              'druh': {'$': e.defNew[i + 6]},
              'type': {'$': e.defNew[i + 7]},
              'moreinfo': {'$': e.defNew[i + 8]},
              'private': {'$': e.defNew[i + 9]},
              'orderNumber': e[i + 10],
            });
          }
        }
      }
      if (!output.defs.def.length) {
        delete output.defs;
        errorsStrings.push(t('ERROR_NO_VALID_DEF'));
        errors++;
      } else {
        output.defs.def.sort((a, b) => parseFloat(a.orderNumber) - parseFloat(b.orderNumber));
        output.defs.def.map(e => delete e.orderNumber);
      }
    }

    const pushTrans = (i, lang) => {
      if (e['transText' + i]) {
        json = {
          '@number': e['trans' + i],
          '@lang': lang,
          '$': e['transText' + i],
        };
        if (e['transPron' + i]) json['@pron'] = e['transPron' + i];
        if (e['transNote' + i]) json['@note'] = e['transNote' + i];
        output.terms.term.push(json);
      }
    };
    if (!id || entry.terms || e.transNew) {
      output.terms = {};
      output.terms.term = [];
      if (entry) entry.terms.term.map((e, i) => pushTrans(i, e['@lang']));
      if (!id) {
        if (req_trans) req_trans.map((e, i) => pushTrans(i, e));
        else pushTrans(0, defaultLang)
      }
      if (e.transNew) {
        j = (output && output.terms ? output.terms.term.length : 0) + 1;
        for (i = 0; i < e.transNew.length; i += 4) {
          if (e.transNew[i + 1]) {
            json = {
              '@number': e.transNew[i] ? e.transNew[i] : j + (i/4),
              '@lang': newLangs[i] ? newLangs[i] : defaultLang,
              '$': e.transNew[i + 1],
            };
            if (e.transNew[i + 2]) json['@pron'] = e.transNew[i + 2];
            if (e.transNew[i + 3]) json['@note'] = e.transNew[i + 3];
            output.terms.term.push(json);
          }
        }
      }
    }

    if (output && output.terms && output.terms.term && req_trans) {
      const langs = [];
      output.terms.term.forEach((e) => {
        langs.push(e['@lang']);
      });
      req_trans.forEach((e) => {
        if (!langs.includes(e)) {
          errorsStrings.push(t('ERROR_REQ_TRANS_MISSING_ONE') + ': ' + e.toUpperCase() + ' – ' + getNameFromCode(e));
          errors++;
        }
      });
    } else {
      errorsStrings.push(t('ERROR_REQ_TRANS'));
      errors++;
    }

    if (!id || entry.imgs || e.picNew) {
      output.imgs = {};
      output.imgs.img = [];
      if (entry && entry.imgs && entry.imgs.img && entry.imgs.img.length) {
        for (i = 0; i < (entry.imgs.img.length); i++) {
          if (e['pic$' + i]) {
            output.imgs.img.push({
              '$': e['pic$' + i],
            });
          }
        }
      }
      if (e.picNew) {
        for (i = 0; i < e.picNew.length; i++) {
          if (e.picNew[i]) {
            output.imgs.img.push({
              '$': e.picNew[i],
            });
          }
        }
      }
      if (!output.imgs.img.length) delete output.imgs;
      else {
        output.imgs.img.forEach(e => {
          if (!urlRegex({exact: true, strict: false}).test(e.$)) {
            errorsStrings.push(t('ERROR_NOT_RECOGNIZE_AS_URL_IMG') + e.$);
            errors++;
          }
        })
      }
    }

    if (!id || entry.videos || e.vidNew) {
      output.videos = {};
      output.videos.video = [];
      if (entry && entry.videos && entry.videos.video && entry.videos.video.length) {
        for (i = 0; i < (entry.videos.video.length); i++) {
          if (e['vid$' + i]) {
            output.videos.video.push({
              '$': e['vid$' + i],
            });
          }
        }
      }
      if (e.vidNew) {
        for (i = 0; i < e.vidNew.length; i++) {
          if (e.vidNew[i]) {
            output.videos.video.push({
              '$': e.vidNew[i],
            });
          }
        }
      }
      if (!output.videos.video.length) delete output.videos;
      else {
        output.videos.video.forEach(e => {
          if (!urlRegex({exact: true, strict: false}).test(e.$)) {
            errorsStrings.push(t('ERROR_NOT_RECOGNIZE_AS_URL_VIDEO') + e.$);
            errors++;
          }
        })
      }
    }

    if (!id || entry.exlinks || e.exlNew) {
      output.exlinks = {};
      output.exlinks.exlink = [];
      if (entry && entry.exlinks && entry.exlinks.exlink && entry.exlinks.exlink.length) {
        for (i = 0; i < (entry ? entry.exlinks.exlink.length : 1); i++) {
          if (e['exl$' + i]) {
            output.exlinks.exlink.push({
              '$': e['exl$' + i],
            });
          }
        }
      }
      if (e.exlNew) {
        for (i = 0; i < e.exlNew.length; i += 2) {
          if (e.exlNew[i + 1]) {
            output.exlinks.exlink.push({
              '$': e.exlNew[i + 1],
            });
          }
        }
      }
      if (!output.exlinks.exlink.length) delete output.exlinks;
      else {
        output.exlinks.exlink.forEach(e => {
          if (!urlRegex({exact: true, strict: false}).test(e.$)) {
            errorsStrings.push(t('ERROR_NOT_RECOGNIZE_AS_URL_EXLINK') + e.$);
            errors++;
          }
        })
      }
    }

    const tree = (what) => {
      if (newSees[what] && !(output.hyper)) {
        output.hyper = [];
      }
      if (output.hyper) {
        deleted.hyper.map(e => output.hyper = output.hyper.filter(f => f['@id'] !== e));
        if (newSees[what]) {
          if (!output.hyper) output.hyper = [];
          for (i = 0; i < newSees[what].length; i++) {
            output.hyper.push({'@id': newSees[what][i]['@entry-id'], '@term': newSees[what][i].$});
          }
        }
        if (!output.hyper.length) delete output.hyper;
      }
    };

    const sees = (what) => {
      if (newSees[what] && !(output[what + 's'] && output[what + 's'][what])) {
        output[what + 's'] = {[what]: []};
      }
      if (output[what + 's'] && output[what + 's'][what]) {
        for (i = output[what + 's'][what].length; i >= 0; i--) {
          if (deleted[what + 's'][i]) output[what + 's'][what].splice(i, 1);
        }
        if (newSees[what]) {
          for (i = 0; i < newSees[what].length; i++) {
            output[what + 's'][what].push(newSees[what][i])
          }
        }
        if (!output[what + 's'][what].length) delete output[what + 's'];
      }
    };

    tree('path');
    sees('synonym');
    sees('antonym');
    sees('homonym');

    if (e.comment) output.comment = e.comment;

    if (e.approval !== undefined) output.schvaleni = e.approval ? "true" : "false";

    setErrorsState(errors);
    setErrorsStringsState(errorsStrings);
    if (errors) window.scrollTo(0, 0);
    return output;
  };

  const output = buildOutputValuesFromForm(data);
  const abbrev = output['@abbrev'];
  if (!errors || (typeof abbrev === "string" ? abbrev === "true" : abbrev)) {
    setLoadingSubmit(true);
    const updateResponse = (await update(output, id));
    setLoadingSubmit(false);
    History.push('/tezaurus/term/' + updateResponse.id);
  }
};

export default handleSubmit
