export default {
  locale: 'en',
  Appbar: {
    CENTRAL: 'Central',
    SETTINGS: 'Settings',

    ADD: 'Add',
    FILTER: 'Filter',
    LOADING: 'Loading...',
    LOGOUT: 'Logout',
    LOGIN: 'Login',
    REGISTER: 'Register',
    CLIENT: 'Client',
    ADMINISTRATOR: 'Administrator',
    OVERVIEW: 'Overview',
    TRANSACTIONS: 'Transactions History',
    TREND: 'Development',
    ALL_USERS: 'Users Overview',
    ALL_CARDS: 'Cards Overview',
    ALL_ACTIVITIES: 'Activities Overview',
    USER_PROFILE: 'User Profile',
    CHANGE_PASSWORD: 'Change Password',
    USER: 'User',
  },
  Search: {
    SEARCH_DOT: 'Search...',
    APPROVED: 'Approved',
    WIP: 'Work In Progress',
    SUGGESTED: 'Suggested',
    ALL: 'All',
    ERROR: 'Error',
    AT_LEAST_3: 'Enter at Least 3 Characters'
  },
  Enhanced: {
    SAVE: 'Save',
    SAVED: 'Saved Succesfully',
    LOADING: 'Saving in Progress',
  },

  Homepage: {
    INFO: 'Information',
    SETTINGS: 'Settings',
    EDITED_TERMS: 'Edited terms',
    CONTACT: 'Contact',
    SEARCH_ALPHABET: 'Browse Dictionary',
    HELP: 'Help',
    STATS: 'Statistics',
    STATS_MORE: 'Statistics detailed',
    REMARK: 'Send remark',
    APP_DEV: 'Application developed Centrum ZPJ FI MU',
    APP_DEV_MORE: 'Information and suggestions',
    TACR: 'Creation of this software was supported by Technology Agency of the Czech Republic, Programme Beta2, Project TITIMV702.',

    HEADER_CONT: 'Contact information',
    ORGANIZATION: 'Organization',
    POVERENA_ORGANIZACE: 'Designated organization',
    CONTACT_PERSON: 'Contact person',
    CONTACT_PHONE: 'Contact phone',
    CONTACT_EMAIL: 'Contact e-mail',
  },
  HomepageC: {
    LIST: 'List of Dictionaries',
    ADD_NEW: 'Add New'
  },
  HomepageTezC: {
    TIME_REGISTER: 'Registration Date:',
    TIME_UPDATE: 'Date of Last Change:',
    TERMS_COUNT: 'Terms Count:',
    URL: 'URL:',

    CONTACT: 'Contact Information',
    CONTACT_PERSON: 'Contact Person:',
    CONTACT_PHONE: 'Contact Phone:',
    CONTACT_EMAIL: 'Contact email:',
    DESCRIPTION: 'Dictionary Description',
  },
  StaticInfo: {
    TOTAL: 'Total count of terms',
    TOTAL_BY_LANG: 'Sorted by languages',
    TOTAL_DEFS: 'Total count of definitions',
    TOTAL_BY_DOM: 'Count of definitions by domains',
  },

  SearchResults: {
    FILTER: 'Filtered Search',
    SEARCH_TEXT: 'Searched Term',
    TYPES: 'Term Type',
    DOMAINS_SELECT: 'Term Domain',
    DATE_RANGE: 'Date of Change',
    DATE_CHANGE: 'Date of Establishment',
    AUTHOR: 'Author',
    STATE: 'Term Status',
    STATE_PUBLIC: 'Public',
    STATE_WORK: 'Work in Progress',
    KIND: 'Term Type',
    SHORT: 'Is the term an abbreviation?',
    SHORT_YES: 'Yes',
    SHORT_NO: 'No',
    DEFINITION: 'Text of the Definition',
    SEARCH: 'Search',

    ALPHABET: 'Alphabetic Search',
    NO_RESULTS_FOUND: 'No Terms Found',
    NEW: 'new',
    NEW_TERM_SUGGEST: 'Suggest new term',

    NO_RESULTS: 'Your search did not match any terms',
    LOADING: 'Loading...',
    ERROR: 'We\'re sorry, there was an error processing your request',

    EXPORT_SELECTED: 'Download selected',
    PDF: 'Download PDF',
    PDF_SHORT: 'Download shortened PDF ',
    RTF: 'Download RTF',
    RTF_SHORT: 'Download shortened',
    XML: 'Download XML',
    SKOS: 'Download SKOS',
    JSON: 'Download JSON',
  },
  TermAdd: {
    ADD_TERM: 'Add New Term',
    TERM: 'Term...',
    SEARCH_TEZ: 'Search Thesauri',
    CREATE: 'Create New Term',
    NO_RESULTS: 'Your search did not match any terms',
  },

  Settings: {
    SETTINGS: 'Settings',
    TECH: 'Technical',
    DICTIONARY: 'Dictionary',
    USERS: 'Users',
    IE: 'Import/export',
    LIST_OF_RIGHTS: 'List of User\'s Rights',
    DESCRIPTION: 'Here you can change public information about your dictionary.',
    INFO: 'Metadata',
    THEME: 'Design',
    THEME_CREATOR: 'Theme Creation',
    DESCRIPTION_THEME: 'Choose the colors, upload a logo and press the button "Generate".',
    GENERATE: 'Generate',
    COLORS: 'Colors Setting',
    LOGO: 'Logo Upload',
    MSG_OK: 'Settings saved, new design will be generated in a minute.',
    MSG_ERROR: 'Error while saving settings.',

    TEXT_COLOR: 'Text Colour',
    SEGMENT_BACKGROUND: 'Segment Colour',
    BODY_BACKGROUND: 'Background Colour',
    BAR_COLOR: 'Bar – Text',
    BAR_BACKGROUND: 'Bar – Background',
    TREE_NODE: 'TREE_NODE',
    VISUALIZATION_COLOR: 'VISUALIZATION_COLOR',
    POSITIVE: 'POSITVE',
    FOCUS_COLOR: 'FOCUS_COLOR',
    ERROR_COLOR: 'ERROR_COLOR',

    APPROVAL_PROCESS: 'Approval Process',
    COMPLEX: 'Complex',
    SIMPLE: 'Simplified',

    DRAG: 'Drag \'n\' drop some files here, or click to select files',
    DROP: 'Drop the files here...',

    SHOW_TREE: 'Show Tree',
    SMTP_SERVER: 'SMTP Server Address',
    SMTP_PORT: 'SMTP Server Port',
    SMTP_USER: 'SMTP Server User',
    SMTP_PASS: 'SMTP Server Password',
    LOG_PATH: 'Logs Address',
    BACKUP_PATH: 'Backup - path',
    BACKUP_TIME: 'Backup Creation Interval',
    BACKUP_URL: 'Backup - URL', 
    BACKUP_USER: 'Backup - login', 
    BACKUP_PASS: 'Backup - password', 
    WEEK: 'Weekly',
    DAY: 'Daily',
    NEVER: 'Never',

    EXAMPLES: 'Number of examples',
    EXAMPLE_LENGTH: 'Max. lenght of examples', 
    DOMAINS: 'Domains',
    TYPES: 'Types',
    KINDS: 'Kinds',
    LANGUAGES: 'Languages',
    REQ_TRANS: 'Required Languages',
    SKOS: 'SKOS',
    DICTIONARIES: 'Dictionaries',
    NOTIFICATION_AFTER_DAYS: 'Notify of pending tasks after number of days',
    ADD_TO_DROPDOWN: 'Add New Item: ',
    ERRORS_FOUND: 'There are errors in the form', 
    ERROR_DICT_SKOS_NOT_URL: 'Unknown URL', 

    NEW_USER: 'Create New User',

    CREATE_BACKUP: 'Create backup', 
    DB: 'Backup database', 
    APP: 'Backup application',
    LOGS: 'Backup logs', 
    BACKUP_ACTION: 'Backup',

    SURE: 'Are you sure you want to delete this user?',
    EDIT: ' – User Settings',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
    SAVE: 'Saved',
    SAVED: 'Saved Succesfully',
  },
  UserEdit: {
    SETTINGS: 'Edit Profile',
    SAVED: 'Saved',
    LOGIN: 'Login',
    NAME: 'Full Name',
    PASSWORD: 'Password',
    PHONE: 'Contact Phone',
    EMAIL: 'Email',
    ORG: 'Organization',
    ROLES: 'User Rights',
    NOTES: 'Note',
    SAVE: 'Save'
  },
  AddNew: {
    ADD_NEW: 'Add New Dictionary',
  },
  Term: {
    404: 'Term not found',
    LOADING: 'Loading...',
    ERROR: 'We\'re sorry, there was an error processing your request',
    NEW_TERM: 'New Term',
    PRINT: 'Print the Page',
    XML: 'Download XML',
    SKOS: 'Download SKOS',
    JSON: 'Download JSON',
    PDF: 'Download PDF',
    PDF_SHORT: 'Download short PDF',
    RTF: 'Download RTF',
    RTF_SHORT: 'Download short RTF',
    ABBREV: 'abbreviation',
    DATE: 'date',
    TRANSLATE: 'Translations',
    SEARCH_SKOS: 'Search in external SKOS',
    DOMAINS: 'Domains',
    DEFINITION: 'Definitions',
    SOURCE: 'Source/Quote',
    LAW: 'Source from a Law',
    MATH: 'Formula',
    TABLE: 'Tables',
    OBOR: 'Domain',
    DRUH: 'Kind',
    TYPE: 'Type',
    MORE_INFO: 'Additional info',
    PRIVATE_INFO: 'Private notice',
    LINKS: 'Links',
    VISUALIZATION: 'Visualization of structure',
    SUPERIOR: 'Superior Terms',
    ADD_NEW_TREE: 'Add New Tree',
    SYN: 'Synonyms',
    ANT: 'Antonyms',
    HOM: 'Homonyms',
    ADD_NEW_SEE: 'Add New Term',
    WILL_BE_ADDED: 'Terms to Be Added:',
    EXAMPLES: 'Usage Examples',
    PICS: 'Pictures',
    PICS_EDIT: 'Pictures (as link)',
    VIDS: 'Videos',
    VIDS_EDIT: 'Videos (as link)',
    OTHERS: 'Links to Other Thesauri',
    SOURCES: 'Sources',
    META: 'Terms Status',
    AUTHOR: 'Author',
    CREATION_TIME: 'Creation Date',
    PUBLISHED_TIME: 'Publication Date',
    UPDATE_TIME: 'Last Modified',
    HISTORY: 'Editing History',
    HISTORY_VERSIONS: 'Versions History',
    SHOW: 'Show',
    EDIT: 'Edit',
    EDIT_SUGGEST: 'Suggestion for edit',
    COMMENT: 'Editing Comment',

    ERRORS_FOUND: 'Errors Found in Forms',
    TRANS_TEXT: 'Meaning',
    TRANS_PRON: 'Pronunciation',
    TRANS_NOTE: 'Note',
    TRANS_SHORT: 'Abbreviation',
    ERROR_SELECT_DOMAIN_FOR_DEF: 'Definition Has No Chosen Domain: ',
    ERROR_MULTIPLE_DIFFERENT_DOMAINS: 'Definition got multiple Domains: ',
    ERROR_SOURCE_FOR_DEF: 'Definition without source: ',
    ERROR_NO_VALID_DEF: 'At least one definition is required',
    ERROR_REQ_TRANS: 'Translations Missing',
    ERROR_REQ_TRANS_MISSING_ONE: 'Translation to Required Language Missing',
    ERROR_NOT_RECOGNIZE_AS_URL_IMG: 'Image URL not recognized:',
    ERROR_NOT_RECOGNIZE_AS_URL_VIDEO: 'Video URL not recognized:', 
    ERROR_NOT_RECOGNIZE_AS_URL_EXLINK: 'Link URL not recognized:', 

    MODAL_HEADER: 'Suggested Possible Translation',
    NO_RESULTS: 'No Results Found',

    CLOSE: 'Close',
    DELETE: 'Delete',
    DELETE_SURE: 'Are you sure you want to delete the term?',
    SAVE: 'Save',
    APPROVE: 'Approve',
    DENY: 'Deny',
    SURE_APPROVE: 'Are you sure, that you want to approve those changes?',
    SURE_DENY: 'Are you sure, that you want to approve those changes?',
    REQUIRED: ' (required)',
    YES: 'Yes',
    CANCEL: 'Cancel',
    RETURN_TO_REWORK: 'Return to rework',
    RETURN_SURE: 'Are you sure you want to return the term for rework?',

    TERMS_EDITED: 'Edited terms',
    WAITING_FOR: 'Waiting for'
  },
  TezInfoForm: {
    NAME: 'Dictionary Name',
    URL: 'Application URL',
    ORG: 'Name of Organisation',
    PERSON: 'Contact Person',
    PHONE: 'Contact Phone',
    MAIL: 'Contact Email',
    NAME_SHORT: 'Abbreviated Name',
    COMMISSION: 'Comission',
    APPR_ORG: 'Authorised Organisation',
    DESCRIPTION_FIELD: 'Description',
    DESCRIPTION_FIELD_EN: 'Description in English',
    INFO_FIELD: 'Basic Information',
    INFO_FIELD_EN: 'Basic Information in English',
    SEND: 'Send',
  },
  Notifications: {
    NOTIFICATIONS: 'Notifications',
    FILTER: 'Filter',
    SEARCH_TEXT: 'Term',
    DATE_RANGE: 'Date Range',
    ROLES: 'Roles',
    ALL: 'All',
    SERVER: 'Server',
    LOCAL: 'Local',
    SEARCH: 'Search',
    READ: 'Mark as Read',
    UNREAD: 'Mark as Unread',
  },
  Remark: {
    REMARK: 'Remark',
    SAVED: 'Saved successfully',
    NAME: 'Your name',
    EMAIL: 'Your email',
    TEXT: 'Text of your remark',
    SEND: 'Send',
  }
};
