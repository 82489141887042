import * as React from 'react';
import {Link} from "react-router-dom";
import LineTo from 'react-lineto';
import {get_viz} from "./_actions";

const Visualization = ({id}) => {
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setData(undefined);
    fetchData(id);
  }, [id]);

  const fetchData = async (id) => setData(await get_viz(id));

  return (
    data ? (
      <div id='visualization'>
        <div className='visualizationHyper'>
          {data.hyper.map(e => (
            <div className={e.id}><Link to={'/tezaurus/visualization/' + e.id}>{e.term}</Link></div>
          ))}
        </div>
        <div className='visualizationRoot'>
          <span className='visualizationRootL'/>
          <Link to={'/tezaurus/term/' + id} className='visualizationRootAnchor'>{data.term.term}</Link>
          <span className='visualizationRootR'/>
        </div>
        <div className='visualizationChildren'>
          {data.child.map(e => (
            <div className={e.id}><Link to={'/tezaurus/visualization/' + e.id}>{e.term}</Link></div>
          ))}
        </div>

        {data.hyper.map(e => (
          <LineTo from={e.id} to='visualizationRootL' zIndex={-1} delay/>
        ))}
        {data.child.map(e => (
          <LineTo from='visualizationRootR' to={e.id} zIndex={-1} delay/>
        ))}
      </div>
    ) : data === undefined ? (
      <div id='visualization'>
        <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '3em'}}/>
      </div>
    ) : (
      <div id='visualization'>
        <h1 style={{color: 'red'}}>ERROR</h1>
      </div>
    )
  );
};

export default Visualization;
