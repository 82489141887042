import * as React from 'react';
import {translate} from "react-translate";
import {Flag, Header, Icon, Input, Segment, Select} from 'semantic-ui-react';
import {ArrayField, Text, useFormApi} from "informed";

import EnhancedModal from "../../components/enhanced/EnhancedModal";
import Context from "../../Context";
import {TextH} from "../../components/informedHighlight";

const numberOfFields = 4;
const addNewTran = add => {
  for (let i = 0; i < numberOfFields; i++) add();
};

const Translate = ({edit, entry, newTerm, selectOptions, handleSelect, defaultLang, hintTrans, hintTransNew, changed = [], t}) => {
  const {settings} = React.useContext(Context);

  const [modal, setModal] = React.useState(false);
  const formApi = useFormApi();

  const handleHintTrans = async (lang, i) => {
    setModal(true);
    setModal({data: (await hintTrans(lang)), id: 'transText' + i});
  };

  const handleHintTransNew = async (i, field) => {
    setModal(true);
    setModal({data: (await hintTransNew(i)), id: field});
  };

  const handleHintSelect = (id, trans) => {
    formApi.setValue(id, trans);
    setModal(false);
  };

  const hintTransHider = (value, id) => {
    const el = document.getElementById(id);
    if (value) el.style.display = 'none';
    else el.style.display = '';
    return undefined;
  };

  return (
    <>
      <Header as='h3' attached='top'>
        <Icon name='translate'/>{t('TRANSLATE')}
      </Header>
      {!edit ? (
        entry.terms.term.length && (
          <Segment attached='bottom'>
            {settings && settings.dict && settings.dict.skos && !!settings.dict.skos.length && (
              <a style={{position: 'absolute', right: '1em', top: '-2.6em'}}
                 href={'/tecu?action=search_skos&id=' + entry['@id']} target='_blank' rel='noopener noreferrer'>
                {t('SEARCH_SKOS')}
              </a>
            )}
            {entry.terms.term.map((e, i) => (
                <p key={'flag' + i}>
                  <Flag title={e['@lang']} name={e['@lang']}/>
                  {e.$}
                  {e.hasOwnProperty('@pron') && <span style={{opacity: .6, fontWeight: 'bold'}}> [<i>{e["@pron"]}</i>]</span>}
                  {e.hasOwnProperty('@note') && <span style={{opacity: .4}}> ({e["@note"]})</span>}
                </p>
            ))}
          </Segment>
        )
      ) : (
        (newTerm || (entry.terms && !!entry.terms.term.length)) && (
          <Segment attached='bottom' className='termForm-trans'>
            <div className='termForm-heading'>
              <span>{t('TRANS_TEXT')}</span>
              <span>{t('TRANS_PRON')}</span>
              <span>{t('TRANS_NOTE')}</span>
            </div>
            {!newTerm ? entry.terms.term.map((e, i) => (
                <Input key={'flag' + i} className={(changed === null || (changed && changed[i] === null)) && 'changedDiff-NewBlock'}>
                  <TextH field={'trans' + i} type='number' disabled={!i}/>.&nbsp;
                  <Flag title={e['@lang']} name={e['@lang']}/>
                  <TextH field={'transText' + i} required={!i} validateOnBlur validate={(v) => hintTransHider(v, 'transText' + i + 'hint')}/>
                  <Icon id={'transText' + i + 'hint'} name='search' link size='small' onClick={() => handleHintTrans(e['@lang'], i)} style={{display: 'none'}}/>
                  <TextH field={'transPron' + i}/>
                  <TextH field={'transNote' + i}/>
                  {changed && changed[i] && (
                    <div className='changedDiff-transParent'>
                      {changed[i]['@number'] || changed[i]['@number'] === null ? (
                        <p className='changedDiff'>{changed[i]['@number']}</p>
                      ) : (
                        <p className='changedDiff changedDiff-opaque'/>
                      )}.&nbsp;
                      {changed[i]['@lang'] ? <Flag title={changed[i]['@lang']} name={changed[i]['@lang']}/> : <Flag title={e['@lang']} name={e['@lang']}/>}
                      {changed[i].$ || changed[i].$ === null ? (
                        <p className='changedDiff'>{changed[i].$}</p>
                      ) : (
                        <p className='changedDiff changedDiff-opaque'/>
                      )}
                      {changed[i]['@pron'] || changed[i]['@pron'] === null ? (
                        <p className='changedDiff'>{changed[i]['@pron']}</p>
                      ) : (
                        <p className='changedDiff changedDiff-opaque'/>
                      )}
                      {changed[i]['@note'] || changed[i]['@note'] === null ? (
                        <p className='changedDiff'>{changed[i]['@note']}</p>
                      ) : (
                        <p className='changedDiff changedDiff-opaque'/>
                      )}
                    </div>
                  )}
                </Input>
            )) : (
              settings.dict && settings.dict.langs && settings.dict.langs.map((e, i) => (
                <Input key={'flagNew' + i}>
                  <Text field={'trans' + i} type='number' disabled={!i} className={'hgltd'} initialValue={i + 1}/>.&nbsp;
                  <Flag title={e} name={e} className={'hgltd'}/>
                  <Text
                    field={'transText' + i}
                    required={settings.dict.req_trans.indexOf(e) > -1}
                    className={'hgltd' + (settings.dict.req_trans.indexOf(e) > -1 ? ' rqField' : '')}
                    initialValue={!i && newTerm}
                  />
                  <Icon id={'transText' + i + 'hint'} name='search' link size='small' onClick={() => handleHintTrans(e, i)}/>
                  <Text field={'transPron' + i} className={'hgltd'}/>
                  <Text field={'transNote' + i} className={'hgltd'}/>
                </Input>
              ))
            )}
            <ArrayField field='transNew'>
              {({add, fields}) => (
                <>
                  {fields.map((e, i) => (
                    (i !== undefined && i % numberOfFields === 0) ? (
                      <Input key={e.key}>
                        <Text field={fields[i].field} type='number' className={'hgltd'}
                              initialValue={
                                ((entry && entry.terms) ? (
                                  entry.terms.term.length
                                ) : (settings.dict && settings.dict.req_trans && settings.dict.req_trans) ? (
                                  settings.dict.req_trans.length
                                ) : 1) + 1 + (i / numberOfFields)
                              }/>.&nbsp;
                        <Select compact options={selectOptions} defaultValue={defaultLang} className={'hgltd'}
                                onChange={(e, option) => handleSelect(option, i)}/>
                        <Text field={fields[i + 1].field} validateOnBlur validate={(v) => hintTransHider(v, 'transNewHint' + i)} className={'hgltd'}/>
                        <Icon id={'transNewHint' + i} name='search' link size='small' onClick={() => handleHintTransNew(i, fields[i + 1].field)}/>
                        <Text field={fields[i + 2].field} className={'hgltd'}/>
                        <Text field={fields[i + 3].field} className={'hgltd'}/>
                      </Input>
                    ) : <></>
                  ))}

                  <Icon onClick={() => addNewTran(add)}
                        name='add circle' className='termForm-addCircle'/>
                </>
              )}
            </ArrayField>

          </Segment>
        )
      )}
      <EnhancedModal
        what='trans'
        handleClick={handleHintSelect}
        modal={modal}
        setModal={setModal}
      />
    </>
  );
};

export default translate('Term')(Translate);
