import * as React from 'react';
import {translate} from 'react-translate';
import {Icon} from 'semantic-ui-react';

import {get_path, get_tree} from "./_actions";
import History from "../history";
import AccordionChild from "../components/enhanced/AccordionChild";

const TermSidebar = ({visible, setVisible, sideScale, width, id_prop}) => {
    const [data, setData] = React.useState([]);
    const [path, setPath] = React.useState([]);

    React.useEffect(() => {
        fetchData();
    }, [id_prop]);

    const fetchData = async () => {
        setData(await get_tree());
        if (id_prop) await fetchPath()
    };

    const fetchPath = async () => {
        const newPath = (await get_path(id_prop));
        if (newPath) setPath(newPath);
    };

    const onClick = (event, id) => {
        event.preventDefault();
        History.push('/tezaurus/term/' + id);
    };

    return (
        <>
            {!visible && <Icon id='termSide-show' name="angle double right" onClick={() => setVisible(true)}/>}

            <div id='termSide'>
                <div style={visible ? {width: width + 'px'} : {width: width + 'px', marginLeft: '-' + width + 'px'}}>
                    <div id='termSide-cont'>
                        {data.length ? (
                            data.map((e, i) => (
                                <AccordionChild key={'termSide' + i} e={e} i={i} id_prop={id_prop} path={path} onClick={onClick}/>
                            ))
                        ) : (
                            <div style={{textAlign: 'center', marginTop: '3em', pointerEvents: 'none'}}>
                                <i className="spinner loading icon"
                                   style={{opacity: 0.4, fontSize: '2em', transformOrigin: 'inherit'}}/>
                            </div>
                        )}
                    </div>
                    <div id='termSide-scale' onMouseDown={sideScale}/>
                </div>
            </div>
        </>
    );
};

export default translate('Term')(TermSidebar);
