import * as React from "react";
import Accordion from "./Accordion";

const AccordionChild = ({e, i, id_prop, path, onClick}) => (
  e.expandable && e.expandable.length ? (
    <Accordion data={e} id_prop={id_prop} key={'expAcc' + i} path={path} onClick={onClick}/>
  ) : (
    <a className={e.id === id_prop ? 'current' : undefined} onClick={(v) => onClick(v, e.id)}
       href={'/tezaurus/term/' + e.id} key={'exp' + i}>{e.head}</a>
  )
);

export default AccordionChild
