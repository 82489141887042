import * as React from 'react';
import {translate} from 'react-translate';
import {Header, Segment, Button, Icon} from 'semantic-ui-react';

import EnhancedSearchWithModal from "../../components/enhanced/EnhancedSearchWithModal";

const TermLinksTree = ({t, edit, entry, newTerm, title, deleted, setDeleted, onClickNavigate, newSees, setNewSees, what}) => {
  const onClickRemoveTree = (v, id) => {
    v.preventDefault();

    let tmp = JSON.parse(JSON.stringify(deleted));
    tmp.hyper.push(id);
    setDeleted(tmp);
  };

  const newSeesRemove = (i) => {
    let tmp = JSON.parse(JSON.stringify(newSees));
    tmp[what].splice(i, 1);
    setNewSees(tmp);
  };

  return (
    <Segment attached={(edit || entry.synonyms || entry.antonyms || entry.homonyms) ? true : 'bottom'} id='term-tree'>
      <Header as='h3'>
        {t('SUPERIOR')}
      </Header>
      {!newTerm && entry.paths && entry.paths.path && entry.paths.path.map((e, j) => (
        <Segment compact key={'path' + j} style={{display: (edit && deleted.hyper.includes(e.path_node[0]['@id'])) ? 'none' : false}}>
          {e.path_node.map((e, i) => (
            <div key={'path_node' + j + '-' + i}>{i > 0 && <Icon name="angle down"/>}<a
              href={'/tezaurus/term/' + e['@id']}
              onClick={(v) => onClickNavigate(v, e['@id'], false)}>{e['@term']}</a></div>
          ))}
          {edit && (
            <Button circular negative icon='trash alternate outline'
                    onClick={(v) => onClickRemoveTree(v, e.path_node[0]['@id'])}/>
          )}
        </Segment>
      ))}

      {edit && (
        <Segment compact>
          <Header as='h5'>{t('ADD_NEW_TREE')}</Header>
          <EnhancedSearchWithModal
            withModal idRequired={t('ID_IS_REQUIRED')}
            title={title} what={what} newSees={newSees} setNewSees={setNewSees}
          />

          {newSees[what] && newSees[what].length > 0 && (
            <div className='newSees-willBeAdded newSees-willBeAddedTREE'>
              <p>{t('WILL_BE_ADDED')}</p>
              {newSees[what].map((e, i) => (
                <p key={what + e.$ + i} onClick={() => newSeesRemove(i)}>
                  {e.$} {e['@entry-id'] && <span>ID: {e['@entry-id']}</span>}
                </p>
              ))}
            </div>
          )}
        </Segment>
      )}
    </Segment>
  );
};

export default translate('Term')(TermLinksTree);
