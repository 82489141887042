import {customFetch as fetch} from '../../utilsNew';
import {ROOT} from '../../constants'

export const search2 = async (text, lang, suggest, ignoreTerm, ignoreAbbrev) => {
    let params = '&search=' + text;
    if (lang) params = params + '&lang=' + lang;
    if (suggest) params = params + '&suggest=true';
    if (ignoreTerm) params = params + '&not_full=true'
    if (ignoreAbbrev) params = params + '&not_abbr=true'

    try {
        return await fetch(`${ROOT}/tecu?action=search2&format=json${params}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return null;
    }
};

export const searchAll = async (text) => {
    try {
        return await fetch(`${ROOT}/tecu?action=search_all_terms&search=${text}`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const searchWithFilter = async (data) => {
    const body = {};

    body.action = 'search2';
    body.data = data;
    body.format = 'json';

    try {
        return await fetch(`${ROOT}/tecu`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return {entry: null};
    }
};
