import {customFetch as fetch} from '../utilsNew';
import {ROOT} from '../constants'

export const listThesaurus = async () => {
    try {
        return await fetch(`${ROOT}/public/registr?action=list_thesaurus`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const updateThesaurus = async (data) => {
    try {
    const body = {};
            body.action = 'update_thesaurus';
        body.data = data;

        return await fetch(`${ROOT}/public/registr`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return false;
    }
};

export const confirmThesaurus = async id => {
    try {
        return await fetch(`${ROOT}/public/registr?action=confirm_thesaurus&tid=${id}`, {
            credentials: 'include',
        });
    } catch (err) {
        console.warn(err);
        return false;
    }
};

export const deleteThesaurus = async id => {
    try {
        return await fetch(`${ROOT}/public/registr?action=delete_thesaurus&tid=${id}`, {
            credentials: 'include',
        });
    } catch (err) {
        console.warn(err);
        return false;
    }
};

/* USERS handling --------------------------------------------------------------------------------------------------- */

export const get_init = async () => {
    try {
        return await fetch(`${ROOT}/public/registr?action=init`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return {logged_user: [{name: 'ERROR'}]};
    }
};

export const get_users = async () => {
    try {
        return await fetch(`${ROOT}/public/registr?action=get_users`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const save_user = async (data, isNew) => {
    try {
        const body = {};

        body.action = 'save_user';
        body.data = data;
        if (isNew) body.data.new = 1;

        return await fetch(`${ROOT}/public/registr`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const delete_user = async (login) => {
    try {
        return await fetch(`${ROOT}/public/registr?action=delete_user&login=${login}`, {
            credentials: 'include',
            method: 'GET',
        })
    } catch (err) {
        console.warn(err);
        return false;
    }
};

/* SETTINGS handling ------------------------------------------------------------------------------------------------ */

export const get_settings_central = async () => {
    try {
        return await fetch(`${ROOT}/public/registr?action=get_settings`, {
            credentials: 'include',
            method: 'GET',
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};

export const save_settings_central = async (data) => {
    const body = {};

    delete data.rolesFormatted;

    body.action = 'save_settings';
    body.data = data;

    try {
        return await fetch(`${ROOT}/public/registr`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(body),
        });
    } catch (err) {
        console.warn(err);
        return [];
    }
};
