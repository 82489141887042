import * as React from 'react';
import {translate} from 'react-translate';
import {Header, Icon, Segment} from 'semantic-ui-react';

import History from "../../history";
import TermLinksPaths from "./TermLinksPaths";
import TermLinksTree from "./TermLinksTree";

const TermLinks = ({t, edit, entry, deleted, newSees, setNewSees, setDeleted, newTerm, title}) => {
    const onClickNavigate = (v, id, i, what) => {
        v.preventDefault();
        if (edit) {
            if (i !== false) {
                onClickRemoveSee(i, what);
            }
        } else {
            History.push('/tezaurus/term/' + id);
        }
    };

    const onClickRemoveSee = (i, what) => {
        let tmp = JSON.parse(JSON.stringify(deleted));
        tmp[what][i] = true;
        setDeleted(tmp);
    };

    return (
        <>
            {!edit && entry && entry['@id'] && (
              <div style={{position: 'relative'}}>
                  <a style={{position: 'absolute', right: '1em', top: '1.55em'}}
                     href={'/tezaurus/visualization/' + entry['@id']} target='_blank' rel='noopener noreferrer'>
                      {t('VISUALIZATION')}
                  </a>
              </div>
            )}

            <Header as='h3' attached='top'>
                <Icon name='linkify'/>{t('LINKS')}
            </Header>
            {(edit || (!newTerm && entry.paths && entry.paths.path)) && (
                <TermLinksTree
                    edit={edit} entry={entry} newTerm={newTerm} title={title}
                    what={'path'} newSees={newSees} setNewSees={setNewSees}
                    deleted={deleted} setDeleted={setDeleted}
                    onClickNavigate={onClickNavigate}
                />
            )}

            {(edit || entry.synonyms || entry.antonyms || entry.homonyms) && (
                <Segment attached='bottom' id='term-sees'>
                    <TermLinksPaths
                      type={'SYN'} what={'synonym'}
                      edit={edit} entry={entry} newTerm={newTerm} title={title}
                      newSees={newSees} setNewSees={setNewSees}
                      deleted={deleted} setDeleted={setDeleted}
                      onClickNavigate={onClickNavigate}
                    />

                    <TermLinksPaths
                      type={'ANT'} what={'antonym'}
                      edit={edit} entry={entry} newTerm={newTerm} title={title}
                      newSees={newSees} setNewSees={setNewSees}
                      deleted={deleted} setDeleted={setDeleted}
                      onClickNavigate={onClickNavigate}
                    />

                    <TermLinksPaths
                      type={'HOM'} what={'homonym'}
                      edit={edit} entry={entry} newTerm={newTerm} title={title}
                      newSees={newSees} setNewSees={setNewSees}
                      deleted={deleted} setDeleted={setDeleted}
                      onClickNavigate={onClickNavigate}
                    />
                </Segment>
            )}
        </>
    )
};

export default translate('Term')(TermLinks);
