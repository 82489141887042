import * as React from 'react';
import {Container, Segment, List, Icon, Header} from "semantic-ui-react";
import {translate} from "react-translate";

import Context from "../Context";

const StaticContact = ({t}) => {
  const {settings} = React.useContext(Context);

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
      id='staticCont'
    >
      <Container text={true}>
        {settings && settings.meta ? (
          <div style={{display: 'inline-block'}}>
            <Header as='h1' style={{margin: '1em'}}>{t('HEADER_CONT')}</Header>
            <List style={{textAlign: 'left'}}>
              <List.Item>
                <Icon style={{verticalAlign: 'middle'}} name='building outline'/>
                <List.Content>
                  <List.Header>{settings.meta.organization}</List.Header>
                  <List.Description>{t('ORGANIZATION')}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <Icon style={{verticalAlign: 'middle'}} name='shield'/>
                <List.Content>
                  <List.Header>{settings.meta['poverena_organizace']}</List.Header>
                  <List.Description>{t('POVERENA_ORGANIZACE')}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <Icon style={{verticalAlign: 'middle'}} name='user outline'/>
                <List.Content>
                  <List.Header>{settings.meta['contact_person']}</List.Header>
                  <List.Description>{t('CONTACT_PERSON')}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <Icon style={{verticalAlign: 'middle'}} name='phone'/>
                <List.Content>
                  <List.Header>{settings.meta['contact_phone']}</List.Header>
                  <List.Description>{t('CONTACT_PHONE')}</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <Icon style={{verticalAlign: 'middle'}} name='mail outline'/>
                <List.Content>
                  <List.Header>{settings.meta['contact_email']}</List.Header>
                  <List.Description>{t('CONTACT_EMAIL')}</List.Description>
                </List.Content>
              </List.Item>
            </List>
          </div>
        ) : (
          <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '3em', margin: '2em auto'}}/>
        )}
        <br/><br/>
        <div id='DEVS'>
          <a href="http://nlp.fi.muni.cz" target="_blank" rel="noopener noreferrer">{t('APP_DEV')}</a><br/>
          <a href="https://nlp.fi.muni.cz/trac/tezaurus2" target="_blank" rel="noopener noreferrer">{t('APP_DEV_MORE')}</a>
        </div>
      </Container>
    </Segment>
  );
};

export default translate('Homepage')(StaticContact);
