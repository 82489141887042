import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Segment, List, Input, Button} from 'semantic-ui-react';
import {Form, Text} from "informed";

import {searchAll} from "./searchResults/_actionsSearch";
import History from "../history";

const TermAdd = ({t}) => {
  const [data, setData] = React.useState(undefined);

  const searchTez = async (e) => {
    e.preventDefault()
    const value = document.getElementsByClassName('termTez')[0].value;
    if (value) {
      setData(null);
      setData(await searchAll(value));
    }
  };

  const createNewTerm = () => {
    History.push('/tezaurus/termEdit?new=' + document.getElementsByClassName('termTez')[0].value);
  };

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="add square"/>
          {t('ADD_TERM')}
        </Header>
        <br/>
        <Form onSubmit={createNewTerm}>
          <Input fluid><Text className='termTez' field='term' placeholder={t('TERM')} required/></Input><br/>
          <Button onClick={searchTez}>
            {t('SEARCH_TEZ')}
          </Button>
          <Button animated type='submit'>
            <Button.Content visible>{t('CREATE')}</Button.Content>
            <Button.Content hidden>
              <Icon name='arrow right'/>
            </Button.Content>
          </Button>
          {data !== undefined && (
            <Segment style={{marginTop: '3em'}}>
              <List link style={{textAlign: 'left'}}>
                {data && data.length ? (
                  data.map((e) => (
                    <List.Item as='a' href={e.url} target='_blank' key={e.thes_id.toString() + e.term_id}>
                      <List.Header><i>{e.thes_id}</i> – {e.term}</List.Header>
                      {e.definition}
                    </List.Item>
                  ))
                ) : data === null ? (
                  <div style={{opacity: 0.4, fontSize: '3em', textAlign: 'center', padding: '1em 0'}}>
                    <Icon loading name="spinner"/>
                  </div>
                ) : (
                  t('NO_RESULTS')
                )}
              </List>
            </Segment>
          )}
        </Form>
      </Container>
    </Segment>
  );
};

export default translate('TermAdd')(TermAdd);
