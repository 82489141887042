import * as React from 'react';
import {translate} from 'react-translate';
import {Container, Header, Icon, Segment, Button} from 'semantic-ui-react';

import HomepageTez from './HomepageTez';
import {listThesaurus} from './_actionsCentral';
import {authorize} from "../utilsNew";
import ContextUser from "../ContextUser";

const HomepageC = ({history, t}) => {
  const {loggedUser} = React.useContext(ContextUser);

  const [data, setData] = React.useState([]);
  const [admin, setAdmin] = React.useState(undefined);

  React.useEffect(() => {
    getListThesaurus();
  }, []);

  React.useEffect(() => {
    setAdmin(authorize(['referent'], loggedUser.roles))
  }, [loggedUser]);

  const getListThesaurus = async () => {
    setData(await listThesaurus());
  };

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="clipboard list"/>
          {t('LIST')}
        </Header>
        <br/>
        <Button onClick={() => {
          history.push('/public/central/addNew');
        }}>{t('ADD_NEW')}</Button>
        <br/><br/>
        {admin !== undefined ? (
          <div id='tezListParent'>
            {data.map((e) => (
              (e.confirmed === 'true' || admin) ? <HomepageTez data={e} admin={admin}/> : <></>
            ))}
          </div>
        ) : (
          <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
        )}
      </Container>
    </Segment>
  );
};

export default translate('HomepageC')(HomepageC);
