import * as React from 'react';
import {translate} from "react-translate";
import {Container, Header, Icon, Input, Segment} from "semantic-ui-react";
import {Form, Text, TextArea} from "informed";

import History from "../history";
import EnhancedSubmitButton, {handleActionWithSetSaved} from "../components/enhanced/EnhancedSubmitButton";
import {send_remark} from "./_actions";

const Remark = ({match, t}) => {
  const template = match.params.template;
  const [saved, setSaved] = React.useState(false);

  const handleSubmit = async data => handleActionWithSetSaved(setSaved, () => send_remark(data)).then(History.push('/tezaurus'));

  return (
    <Segment
      className='settings-segment'
      textAlign="center"
      vertical={true}
    >
      <Container text={true}>
        {saved ? (
          <Header as="h2" icon={true}
                  style={{color: 'green'}}>
            <Icon name="save outline"/>
            {t('SAVED')}
          </Header>
        ) : (
          <Header as="h2" icon={true}>
            {saved === null ? <Icon name="spinner loading icon" style={{opacity: 0.4}}/> :
              <Icon name="mail outline"/>}
            {t('REMARK')}
          </Header>
        )}
        <br/>
        <br/>
        <Form onSubmit={handleSubmit} className='ui form' initialValues={{text: template ? '#' + template + '\n\n' : undefined}}>
          {t('NAME')}
          <Input fluid><Text required field='name' placeholder={t('NAME')}/></Input><br/>
          {t('EMAIL')}
          <Input fluid><Text required field='email' placeholder={t('EMAIL')} type='email'/></Input><br/>
          {t('TEXT')}
          <TextArea required field='text' placeholder={t('TEXT')}
                    style={{minHeight: '7.5em', display: 'block'}}/><br/>

          <EnhancedSubmitButton saved={saved} title={t('SEND')}/>
        </Form>
      </Container>
    </Segment>
  );
};

export default translate('Remark')(Remark);
