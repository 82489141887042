import React from 'react'
import {translate} from 'react-translate'
import {Container, Header, Icon, Segment, Table} from 'semantic-ui-react'

import {get_waiting} from './_actions'
import Context from '../Context'

const TermEdited = ({t}) => {
  const {settings} = React.useContext(Context);

  const [rawData, setRawData] = React.useState(undefined);
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => void get_waiting().then(r => setRawData(r.waiting)), [])

  React.useEffect(() => {
    if (settings && settings.rolesFormatted && rawData) {
      const rolesDictionary = {}
      const tmp = [...rawData]
      settings.rolesFormatted.forEach(e => rolesDictionary[e.key] = e.text)
      tmp.forEach(term => term.rolesFormatted = term['wait_for'].map(role => rolesDictionary[role]).filter(e => !!e))
      setData(tmp)
    }
  }, [rawData, settings])

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        <Header as="h2" icon={true}>
          <Icon name="edit outline"/>
          {t('TERMS_EDITED')}
        </Header><br/><br/>

        <Segment>
          {data ? (
            <Table celled basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('TERMS_EDITED')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('WAITING_FOR')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.map(e => (
                  <Table.Row>
                    <Table.Cell>
                      <a href={`/tezaurus/termEdit?id=${e.id}`} target='_blank' rel='noopener noreferrer'>{e.term}</a>
                    </Table.Cell>
                    <Table.Cell>
                      {e.rolesFormatted && e.rolesFormatted.map(e => <span>{e}<br/></span>)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em', margin: '2em 0'}}/>
          )}
        </Segment>
      </Container>
    </Segment>
  )
}

export default translate('Term')(TermEdited)
