import * as React from 'react';
import {Button, Container, Header, Icon, Image, Segment} from 'semantic-ui-react';
import {Link} from "react-router-dom";
import {translate} from "react-translate";

import Context from "../Context";
import ContextUser from "../ContextUser";
import {authorize} from "../utilsNew";
import logo from "../assets/img/logo.png";

const Homepage = ({lang, t}) => {
  const {settings} = React.useContext(Context);
  const {loggedUser} = React.useContext(ContextUser);

  return (
    <Segment
      textAlign="center"
      style={{minHeight: '100%', padding: '1em 0em', display: 'flex', alignItems: 'center'}}
      vertical={true}
    >
      <Container text={true}>
        {(settings && settings.meta) ? (
          <>
            <Header as="h2" icon={true}>
              <Image alt={''} src={logo} id="homepage_logo"/><br/>
              {settings.meta.name}
            </Header>
            <Header as='h3' style={{margin: '0 0 2em'}}>
              {settings.meta.organization}
            </Header>
            <p>{settings.meta['description' + lang]}</p>
          </>
        ) : (
          <Header as="h2" icon={true}>
            <Icon name="discussions"/>
            <br/>
            <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
          </Header>
        )}
        <Header/>
        <br/>
        {loggedUser && loggedUser.roles ? (
          <>
            {[
              {link: '/tezaurus/alphabet', label: t('SEARCH_ALPHABET'), restriction: null},
              {link: '/tezaurus/info', label: t('INFO'), restriction: null},
              //{link: '/tezaurus/help', label: t('HELP'), restriction: null},
            ].map(e => (!e.restriction || authorize(e.restriction, loggedUser.roles)) && (
              <Button as={Link} to={e.link} key={e.label} style={{marginBottom: '.25em'}}>{e.label}</Button>
            ))}
            <Button as='a' href='https://docs.google.com/document/d/1dO2xC5H9gpPuOfXWWrzFgmiJ1QqzWD0v8iHscbXkmn0'
                    target='_blank' rel='noopener noreferrer'>{t('HELP')}</Button>
            <Button as={Link} to='/tezaurus/stats'
                    key={t('STATS')} style={{marginBottom: '.25em'}}>{t('STATS')}</Button>
            {authorize(['hl_redaktor', 'asist_hl', 'tajemnik'], loggedUser.roles) && (
              <Button as='a' href='/editor/stats/stats.html'
                      target='_blank' rel='noopener noreferrer'>{t('STATS_MORE')}</Button>
            )}
            {[
              {link: '/tezaurus/editedTerms', label: t('EDITED_TERMS'), restriction: ['hl_redaktor']},
              {link: '/tezaurus/contact', label: t('CONTACT'), restriction: null},
              {link: '/tezaurus/settings', label: t('SETTINGS'), restriction: ['it_admin', 'operator', 'hl_redaktor', 'asist_hl']},
              {link: '/tezaurus/remark', label: t('REMARK'), restriction: null},
            ].map(e => (!e.restriction || authorize(e.restriction, loggedUser.roles)) && (
              <Button as={Link} to={e.link} key={e.label} style={{marginBottom: '.25em'}}>{e.label}</Button>
            ))}
          </>
        ) : (
          <i className="spinner loading icon" style={{opacity: 0.4, fontSize: '2em'}}/>
        )}

        <div id='TACR'>
          <a href="http://nlp.fi.muni.cz" target="_blank" rel="noopener noreferrer">{t('APP_DEV')}</a>
          <div style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/TACR.png)'}}>
            <p>{t('TACR')}</p>
            <a href='http://www.tacr.cz' target='_blank' rel="noopener noreferrer">www.tacr.cz</a><br/>
            <i>Výzkum užitečný pro společnost.</i>
          </div>
        </div>
      </Container>
    </Segment>
  );
};

export default translate('Homepage')(Homepage);
